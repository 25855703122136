import * as React from 'react'
import IndexLayout from '../../layouts'
import Page from '../../components/Page'
import Container from '../../components/Container'

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <p>
          We have a simple, yet elegant user-friendly interface and great customer support. Our pricing is also directly aimed at small to
          medium-scale merchants and we have the most competitive price offering on the market.
        </p>

        <p>
          We differentiate ourselves by three main: things:
          <ol>
            <li>Exceptional customer service</li>
            <li>A very easy to use system.</li>
            <li>
              Cost-effective prices targeting the small to medium scale merchant. Our pricing is currently the most affordable on the
              market.
            </li>
          </ol>
        </p>
      </Container>
    </Page>
  </IndexLayout>
)

export default IndexPage
